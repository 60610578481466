

@font-face{
  font-family: 'KiaLight';
  src: url('../src/assets/font/KIA_Light_0.ttf')
  format('truetype')
}
@font-face{
  font-family: 'kiaBold';
  src:url('../src/assets/font/KIA_Bold_0.ttf')
  format('truetype')
}

@font-face{
  font-family: 'kiaMedium';
  src:url('../src/assets/font/KIA_Medium_0.ttf')
  format('truetype')
}

body {
  /* margin: 0; */
  font-family: "kiaLight", -apple-system, BlinkMacSystemFont,  "KiaMedium", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui.button {
  font-family: 'kiaMedium'
}

.ui.menu .active.item {
  background: rgba(0,0,0,.05);
  color:"#BB162B" !important;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
}

code {
  font-family: "KiaLight", "KiaMedium", "KiaBold" source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5 {
  font-family: 'kiaBold';
}
.ui.header{
  font-family: 'kiaBold';
}