.ui.menu .active.item {
  background: rgba(0,0,0,.05);
  color:"#BB162B" !important;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
  font-family: 'kiaLight';
}
.ui.form {
  font-family: 'kiaLight';
}

.ui.form .field.field input:-webkit-autofill {
  font-family: 'kiaLight';
}



input {
  font-family: 'kiaLight' !important;
}

.con_tit:after {
  content: '';
  display: block;
  width: 70px;
  height: 2px;
  background: #bb162b;
  margin-top: 18px;
}

.ui.menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 1rem 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  background: #fff;
  font-weight: 400;
  border: 1px solid rgba(34,36,38,.15);
  /* -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15); */
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.0);
  border-radius: .28571429rem;
  min-height: 30px;
}

.ui.steps .step.active {
  cursor: auto;
  background: #BB162C;
  color: #FFFFFF;
}

.ui.steps .step.active .title {
  color: #FFFFFF;
}
.ui.steps .step .title {
  font-family: 'KiaMediun','Helvetica Neue',Arial,Helvetica,sans-serif;
  font-size: 13px;
  font-weight: 700;
  color: #454545;
}

.ui.steps .step.active .description {
  font-family: 'KiaBold';
  font-size: 18px;
  color: #FFFFFF;
}

.ui.menu {
  font-family: 'KiaMedium';
  font-weight: 'bold';
  text-transform: 'uppercase';
}
.item {
  text-transform: 'uppercase';

}

textarea {
  font-family: 'KiaLight';
}

.ui.steps .step .description {
  font-family: 'KiaBold';
  font-size: 18px;
  color: #454545;
}

.ui.steps .step.active:after {
  background: #bb162b;
}

.ui.secondary.pointing.menu .active.item {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #bb162b;
  font-weight: 700;
  color: rgba(0,0,0,.95);
}
